import React from "react";
import { navigate } from "gatsby";
import { Container, Button, Row, Col } from "react-bootstrap";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import ExpiredLinkIcon from "../svg/expired-link.svg";

const ExpiredLink: React.FC = () => {
  const handleResetPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <Layout>
      <SEO title="Expired Link" />
      <Container className="text-center my-5">
        <Row className="justify-content-center">
          <Col md={6}>
            <img
              src="https://image.e.sonesta.com/lib/fe9c12727364077f75/m/9/78c989cc-cd8e-4d9f-8d4d-f181034301ae.png"
              alt="Expired"
              className="mb-4 img-fluid"
              style={{ height: "47px", width: '82px' }}
            />
            <h2 className="mb-3 mt-4" style={{ fontSize: "36px", fontWeight: '900', fontFamily:'Roboto', lineHeight:'46px'}}>
              Expired Link
            </h2>
            <div className="expired-link-logo mt-4">
              <ExpiredLinkIcon style={{ height: "81px" }} />
            </div>
            <h5 className="mt-4" style={{fontSize: "18px", fontWeight: '400', fontFamily:'Roboto', lineHeight:'24px'}}>
              You have exceeded the expiration period of this link.
            </h5>

            <Button
              onClick={handleResetPassword}
              variant="dark"
              className="mt-3"
              style={{fontSize: "16px", fontWeight: '700', fontFamily:'Roboto', lineHeight:'26px', padding:'8px 24px 8px 24px'}}
            >
              Reset Password
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ExpiredLink;
